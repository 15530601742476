<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Custom E-mail Domain Configuration</h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <label for="cust_domain" class="switch_option capsule_btn">
                    <h5>Use Custom Email Domain<span>This feature may require a one-time support premium.</span></h5>
                    <input type="checkbox" id="cust_domain" v-model="useCustomEmailSender" @change="enableDisableEmailDomainPrompt($event)" :true-value="1" :false-value="0" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="useCustomEmailSender">
                    <div class="custDomain_info">
                        <p>Setting up your custom domain requires technical knowledge!</p>
                        <h5 class="border-bottom">Checklist</h5>
                        <ul>
                            <li><i class="fas fa-check"></i>Purchased domain name</li>
                            <li><i class="fas fa-check"></i>Domain hosting</li>
                            <li><i class="fas fa-check"></i>Cloudflare account or wildcard SSL certificate</li>
                            <li><i class="fas fa-check"></i>DNS experience</li>
                            <li><i class="fas fa-check"></i>Nameserver experience</li>
                            <li><i class="fas fa-check"></i>Propagation experience</li>
                            <li><i class="fas fa-check"></i>Email name you will be using</li>
                        </ul>
                        <p>If you lack experience with the above we recommend that you purchase our custom domain setup and testing for $97 below or continue using your Thrive Coach subdomain.</p>
                    </div>
                    <div class="action_wpr mt-4">
                        <a href="https://briandevlin.samcart.com/products/custom-email-domain-service/" target="_blank" class="btn cancel_btn border-blue">Purchase E-mail Setup</a>
                    </div>
                    <div class="section_wpr mt-5">
                        <h3 class="sub_header">All E-mail Domains<button type="button" class="add_btn" @click="addEmailDomain = true"><i class="fas fa-plus"></i>Add Domain</button></h3>
                    </div>
                    <div class="result_wpr new small m-0">
                        <div class="actions">
                            <ul class="w-100">
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()">
                                    Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="email-domain-per-page-filter" />
                                </li>
                                <li class="optionDrops search_area ml-auto active">
                                    <input type="text" placeholder="Search" :disabled="emailDomainLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                    <button class="search_btn">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                                <!-- <li class="list_info">
                                    {{ customEmailDomains.from ? customEmailDomains.from : 0 }} - {{ customEmailDomains.to ? customEmailDomains.to : 0 }} of <span>{{ customEmailDomains.total ? customEmailDomains.total : 0 }}</span>
                                </li> -->
                            </ul>
                        </div>
                        <div v-if="emailDomainLoader"><line-loader /></div>
                        <div class="scroll_table">
                            <table class="show_header">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="customEmailDomains.data" v-click-outside="closeToggleDomain">
                                    <tr v-for="(domain, d) of customEmailDomains.data" :key="d">
                                        <td>
                                            <h4>{{ domain.name }}</h4>
                                        </td>
                                        <td>{{ domain.status }}</td>
                                        <td class="action">
                                            <div class="dropdown" :class="{ active: selectedDomainDropdown == domain.id }" @click="toggleDomainOption(domain.id)">
                                                <i class="fas fa-ellipsis-v"></i>
                                                <div class="drp_wrapper">
                                                    <ul>
                                                        <li @click="handleDomainConfigure(domain)">Configure</li>
                                                        <li @click="handleVerifyDomain(domain)" v-if="!domain.verified">Verify</li>
                                                        <li @click="handleSMTPCredential(domain)">SMTP Credentials</li>
                                                        <li @click="activateDomainPrompt(domain)" v-if="!domain.activated">Activate</li>
                                                        <li @click="handleActivateForAllLocation(domain)" v-if="!user.company_id && domain.activated">Activate For All Locations</li>
                                                        <li @click="handleDelete(domain)">Delete</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="Object.keys(customEmailDomains.data).length === 0">
                                        <td colspan="7" class="px-4 text-center">No Records Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ customEmailDomains.from ? customEmailDomains.from : 0 }} - {{ customEmailDomains.to ? customEmailDomains.to : 0 }} of {{ customEmailDomains.total ? customEmailDomains.total : 0 }}</li>
                        </ul>
                    </div>
                    <div class="pagination" v-show="customEmailDomains.total">
                        <pagination v-model="params.page" :range-size="0" :pages="customEmailDomains.last_page" @update:modelValue="handlePagination" />
                    </div>
                    <div v-if="domainConfigure" class="details_wpr">
                        <div class="cardItem_details">
                            <div class="header">
                                <button class="close_btn" @click="domainConfigure = false"><i class="fas fa-chevron-down"></i></button>
                                <h2>Configure Domain</h2>
                            </div>
                            <div class="setting_wpr">
                                <div class="listing_loader2" v-show="domainDNSLoader"><quote-loader /></div>
                                <div v-show="!domainDNSLoader">
                                    <div v-if="domainDNSDetails.domain && domainDNSDetails.domain.id">
                                        <h2>Step 1:</h2>
                                        <div class="extra_info py-2 px-3 mb-5">
                                            <p>Log in to your domain administration panel and find DNS records management panel for the domain: {{ domainDNSDetails.domain.name }}</p>
                                        </div>
                                        <h2>Step 2:</h2>
                                        <h5>Add Sending Records</h5>
                                        <div class="result_wpr small m-0">
                                            <div class="actions">
                                                <p class="info2 px-3">TXT records (known as SPF &amp; DKIM) are required to send and receive email with.</p>
                                            </div>
                                            <table class="dns-table">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Host Name</th>
                                                        <th>Enter This Value</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(sendingRecord, sr) of domainDNSDetails.sending_dns_records" :key="sr">
                                                        <td>{{ sendingRecord.record_type }}</td>
                                                        <td>{{ sendingRecord.name }}</td>
                                                        <td>{{ sendingRecord.value }}</td>
                                                        <td>{{ sendingRecord.valid == 'valid' ? 'Verified' : 'Unverified' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <h5 class="mt-5">Add Receiving Records</h5>
                                        <div class="result_wpr small m-0">
                                            <div class="actions">
                                                <p class="info2 px-3">MX records are recommended for all domains, even if you are only sending messages. Unless you already have MX records for {{ domainDNSDetails.domain.name }} pointing to another email provider (e.g. Gmail), you should update the following records.</p>
                                            </div>
                                            <table class="dns-table">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Host Name</th>
                                                        <th>Priority</th>
                                                        <th>Enter This Value</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(recevingRecord, rr) of domainDNSDetails.receiving_dns_records" :key="rr">
                                                        <td>{{ recevingRecord.record_type }}</td>
                                                        <td>{{ domainDNSDetails.domain.name }}</td>
                                                        <td>10</td>
                                                        <td>{{ recevingRecord.value }}</td>
                                                        <td>{{ recevingRecord.valid == 'valid' ? 'Verified' : 'Unverified' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <h5 class="mt-5">Add Tracking  Records</h5>
                                        <div class="result_wpr small m-0">
                                            <div class="actions">
                                                <p class="info2 px-3">The CNAME record is necessary for tracking opens, clicks, and unsubscribes.</p>
                                            </div>
                                            <table class="dns-table">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Host Name</th>
                                                        <th>Enter This Value</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(trackingRecord, tr) of domainDNSDetails.tracking_dns_records" :key="tr">
                                                        <td>{{ trackingRecord.record_type }}</td>
                                                        <td>{{ trackingRecord.name }}</td>
                                                        <td>{{ trackingRecord.value }}</td>
                                                        <td>{{ trackingRecord.valid == 'valid' ? 'Verified' : 'Unverified' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <h5 class="mt-5">Add DNS TXT record:</h5>
                                        <div class="result_wpr small m-0">
                                            <table class="dns-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Value/Content</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>_dmarc</td>
                                                        <td>v=DMARC1; p=none;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="extra_info py-2 px-3 mt-5">
                                            <p>If you have a problem with domain configuration - contact your domain provider.</p>
                                        </div>
                                        <div class="action_wpr mt-5">
                                            <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                                            <button type="button" class="btn save_btn" @click="handleVerifyDomain(selectedEmailDomain)">Verify</button>
                                        </div>
                                    </div>
                                    <div v-else class="text-center">
                                        <br />
                                        <br />
                                        <p>Something went wrong while fetching the domain configuration, please try after some time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="smtpCredential" class="details_wpr">
                        <div class="cardItem_details">
                            <div class="header">
                                <button class="close_btn" @click="smtpCredential = false"><i class="fas fa-chevron-down"></i></button>
                                <h2>SMTP Credentials</h2>
                            </div>
                            <div class="setting_wpr">
                                <div class="section_wpr mt-4">
                                    <h3><span></span><button type="button" class="add_btn" @click="addEmailDomainSender = true"><i class="fas fa-plus"></i>Add Sender</button></h3>
                                </div>
                                <Form @submit="handleSaveSender" v-slot="{ errors }" v-if="addEmailDomainSender" class="mb-5">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">E-mail</label>
                                            <div class="field_wpr has_prefix" :class="{ 'has-error': errors.email }">
                                                <Field autocomplete="off" type="text" name="email" placeholder="johndoe" v-model="form.sender_name" rules="required" />
                                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                                                <span class="prefix-right">@{{ selectedEmailDomain.name }}</span>
                                            </div>
                                            <ErrorMessage name="email" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="action_wpr mt-4">
                                        <button :disabled="domainDNSLoader" type="button" class="btn cancel_btn" @click="addEmailDomainSender = false">Cancel</button>
                                        <button :disabled="domainDNSLoader" class="btn save_btn">
                                            <i class="fa fa-spin fa-spinner" v-if="domainDNSLoader"></i> {{ domainDNSLoader ? 'Saving' : 'Save' }}
                                        </button>
                                    </div>
                                </Form>
                                <div class="result_wpr small m-0">
                                    <div class="actions">
                                        <ul class="w-100">
                                            <li class="optionDrops sort_list" @click="togglePerPageSenderFilter()">
                                                {{ senderParams.per_page }} <i  class="fas fa-angle-down"></i>
                                                <page-filter v-model="senderParams.per_page" :type="2" :is-dropdown="true" ref="email-domain-sender-per-page-filter" />
                                            </li>
                                            <li>
                                                <input type="text" placeholder="Search" :disabled="customEmailDomainSenderLoader" ref="search" @input="isTypingSender = true" v-model.trim="senderParams.search" />
                                                <i class="fas fa-search"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-if="customEmailDomainSenderLoader"><line-loader /></div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="customEmailDomainSenders.data" v-click-outside="closeToggleSender">
                                            <tr v-for="(sender, s) of customEmailDomainSenders.data" :key="s">
                                                <td>
                                                    <h4>{{ sender.email }}</h4>
                                                </td>
                                                <td>{{ sender.activated ? 'Active' : 'Inactive' }}</td>
                                                <td class="action">
                                                    <div class="dropdown"  :class="{ active: selectedSenderDropdown == sender.id }" @click="toggleSenderOption(sender.id)">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                        <div class="drp_wrapper">
                                                            <ul>
                                                                <li @click="activateSenderPrompt(sender)" v-if="!sender.activated">Activate</li>
                                                                <li @click="handleActivateSenderForAllLocation(sender)" v-if="!user.company_id && selectedEmailDomain.activated && sender.activated">Activate For All Locations</li>
                                                                <li @click="handleSenderDelete(sender)" v-if="!sender.activated">Delete</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pagination" v-show="customEmailDomainSenders.total">
                                    <pagination v-model="senderParams.page" :range-size="0" :pages="customEmailDomainSenders.last_page" @update:modelValue="handleSenderPagination" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <domain-wizard v-model="addEmailDomain" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const DomainWizard = defineAsyncComponent(() => import('@/pages/admin-setting/components/email/DomainWizard'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Email Domain',

        data () {
            return {
                addEmailDomain: false,
                params: {
                    per_page: 5,
                    page: 1,
                    search: '',
                },
                senderParams: {
                    per_page: 5,
                    page: 1,
                    search: '',
                },
                useCustomEmailSender: false,
                domainConfigure: false,
                loader: false,
                smtpCredential: false,
                selectedEmailDomain: {},
                isTyping: false,
                isTypingSender: false,
                addEmailDomainSender: false,
                form: { sender_name: '' },
                selectedDomainDropdown: 0,
                selectedSenderDropdown: 0,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            DomainWizard,
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    setTimeout(function () {
                        vm.getEmailDomains(vm.params);
                    }, 1000);

                    vm.resetForm();
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.domainConfigure  = false;
                vm.smtpCredential   = false;
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getEmailDomains(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getEmailDomains(vm.params);
                        }
                    }
                }
            },

            'senderParams.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTypingSender = false;
                }, 1500);
            },

            isTypingSender (val) {
                const vm = this;

                if (!val) {
                    if (vm.senderParams.search != null) {
                        if (vm.senderParams.search.length >= 2) {
                            vm.senderParams.page = 1;
                            vm.getEmailDomainSenders(vm.senderParams);
                        }
                        if (vm.senderParams.search.length === 0) {
                            vm.senderParams.page = 1;
                            vm.getEmailDomainSenders(vm.senderParams);
                        }
                    }
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getEmailDomains(vm.params);
            },

            'senderParams.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getEmailDomainSenders(vm.senderParams);
            },

            user (user) {
                const vm = this;

                vm.useCustomEmailSender = user.use_custom_email_sender ? user.use_custom_email_sender : 0;
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            customEmailDomains: state => state.authModule.customEmailDomains,
            emailDomainLoader: state => state.authModule.emailDomainLoader,
            domainDNSDetails: state => state.authModule.domainDNSDetails,
            domainDNSLoader: state => state.authModule.domainDNSLoader,
            customEmailDomainSenderLoader: state => state.authModule.customEmailDomainSenderLoader,
            customEmailDomainSenders: state => state.authModule.customEmailDomainSenders,
        }),

        mounted () {
            const vm = this;

            vm.useCustomEmailSender = vm.user.use_custom_email_sender ? vm.user.use_custom_email_sender : 0;
            vm.domainConfigure  = false;
            vm.smtpCredential   = false;
        },

        methods: {
            ...mapActions({
                getEmailDomains: 'authModule/getEmailDomains',
                getEmailDomainSenders: 'authModule/getEmailDomainSenders',
                activateEmailDomain: 'authModule/activateEmailDomain',
                enableDisableEmailDomain: 'authModule/enableDisableEmailDomain',
                emailDomainDNSDetails: 'authModule/emailDomainDNSDetails',
                verifyEmailDomain: 'authModule/verifyEmailDomain',
                deleteEmailDomain: 'authModule/deleteEmailDomain',
                saveEmailDomainSender: 'authModule/saveEmailDomainSender',
                activateEmailDomainSender: 'authModule/activateEmailDomainSender',
                deleteEmailDomainSender: 'authModule/deleteEmailDomainSender',
                activateEmailDomainForLocation: 'authModule/activateEmailDomainForLocation',
                activateEmailDomainSenderForLocation: 'authModule/activateEmailDomainSenderForLocation',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['email-domain-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            togglePerPageSenderFilter () {
                const vm = this;

                const filter = vm.$refs['email-domain-sender-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            toggleDomainOption (id) {
                const vm = this;
                vm.selectedDomainDropdown = id;
            },

            closeToggleDomain() {
                const vm = this;
                vm.selectedDomainDropdown = 0;
            },

            toggleSenderOption (id) {
                const vm = this;
                vm.selectedSenderDropdown = id;
            },

            closeToggleSender () {
                const vm = this;
                vm.selectedSenderDropdown = 0;
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getEmailDomains(vm.params);
            },

            handleSenderPagination (page) {
                const vm = this;

                vm.senderParams.page = page;
                vm.getEmailDomainSenders(vm.senderParams);
            },

            activateDomainPrompt (domain) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Activate Domain?', `This will activate <b>${domain.name}</b> to digitally sign your emails(SPF,DKIM,DMARC).`)
                      option.preConfirm = function() {
                                              return vm.activateEmailDomain(domain.id).then((result) => {
                                                    if (result) {
                                                        vm.getEmailDomains(vm.params);
                                                    }
                                              });
                                          };

                Swal.fire(option);
            },

            enableDisableEmailDomainPrompt (e) {
                // e.target.checked = !e.target.checked;
                const vm = this;

                setTimeout(function () {
                    const status = vm.useCustomEmailSender;
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to ${ status ? 'enable' : 'disable' } custom email sender.`);

                    option.preConfirm = function() {
                                            return vm.enableDisableEmailDomain({ status }).then((result) => {
                                                if (result) {
                                                    // e.target.checked = !e.target.checked;
                                                    // vm.useCustomEmailSender = vm.useCustomEmailSender == 1 ? 0 : 1;
                                                }
                                            });
                                        };

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.useCustomEmailSender = vm.useCustomEmailSender == 1 ? 0 : 1;
                        }
                    });
                }, 10);
            },

            handleDomainConfigure (domain) {
                const vm = this;

                vm.emailDomainDNSDetails({ domain: domain.name });

                vm.domainConfigure = true;
                vm.selectedEmailDomain = domain;
            },

            handleVerifyDomain (domain) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', `You want to verify ${ domain.name } domain.`);
                option.preConfirm = () => {
                    return vm.verifyEmailDomain(domain.id).then((result) => {
                        if (result === 0) {
                            const option2 = Helper.swalWarningOptions('Verification in process', `This may take up to 24-48 hours. We will send an email to ${domain.smtp_login} when your domain is verified. Once verified you will be able to set this as your default email sender.`);
                            Swal.fire(option2);
                        }
                    });
                }

                vm.selectedEmailDomain = domain;
                Swal.fire(option);
            },

            handleDelete (domain) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to delete this domain, you will not be able to recover this domain.`);

                options.preConfirm = () => {
                                          return vm.deleteEmailDomain([domain.id]).then((result) => {
                                              if (result) {
                                                  vm.getEmailDomains(vm.params);
                                              }
                                          });
                                      };

                Swal.fire(options);
            },

            handleSMTPCredential (domain) {
                const vm = this;

                vm.smtpCredential = true;
                vm.selectedEmailDomain = domain;
                vm.senderParams.domain_id = domain.id;

                vm.getEmailDomainSenders(vm.senderParams);
            },

            handleSaveSender (params, { setFieldError }) {
                const vm = this;

                const form = { email: `${vm.form.sender_name}@${vm.selectedEmailDomain.name}`, 'email_domain_id': vm.selectedEmailDomain.id, setFieldError };

                vm.saveEmailDomainSender(form).then((result) => {
                    if (result) {
                        vm.addEmailDomainSender = false;
                        vm.getEmailDomainSenders(vm.senderParams);
                    }
                });
            },

            handleActivateForAllLocation (domain) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to activate your this domain for all locations.`);
                options.preConfirm = () => {
                                          return vm.activateEmailDomainForLocation(domain.id).then((result) => {
                                              if (result) {
                                                  vm.getEmailDomains(vm.params);
                                              }
                                          });
                                      };

                Swal.fire(options);
            },

            activateSenderPrompt (sender) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to activate your email address as your sender address.`);
                options.preConfirm = () => {
                                          return vm.activateEmailDomainSender(sender.id).then((result) => {
                                              if (result) {
                                                  vm.getEmailDomainSenders(vm.senderParams);
                                              }
                                          });
                                      };

                Swal.fire(options);
            },

            handleActivateSenderForAllLocation (sender) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to activate your this email address as your sender address for all locations.`);
                options.preConfirm = () => {
                                          return vm.activateEmailDomainSenderForLocation(sender.id).then((result) => {
                                              if (result) {
                                                  vm.getEmailDomainSenders(vm.senderParams);
                                              }
                                          });
                                      };

                Swal.fire(options);
            },

            handleSenderDelete (sender) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to delete this sender, you will not be able to recover this sender.`);

                options.preConfirm = () => {
                                          return vm.deleteEmailDomainSender([sender.id]).then((result) => {
                                              if (result) {
                                                  vm.getEmailDomainSenders(vm.senderParams);
                                              }
                                          });
                                      };

                Swal.fire(options);
            },

            resetForm () {
                const vm = this;

                vm.addEmailDomain       = false;
                vm.params               = { per_page: 5, page: 1, search: '' };
                vm.senderParams         = { per_page: 5, page: 1, search: '' };
                vm.domainConfigure      = false;
                vm.loader               = false;
                vm.smtpCredential       = false;
                vm.selectedEmailDomain  = {};
                vm.isTyping             = false;
                vm.isTypingSender       = false;
                vm.addEmailDomainSender = false;
                vm.form                 = { sender_name: '' };
                vm.useCustomEmailSender = vm.user.use_custom_email_sender ? vm.user.use_custom_email_sender : 0;
            },
        },
    }
</script>

<style scoped>
    .action_wpr a.btn {
        background: #fff;
        text-decoration: none;
        font-size: 11px;
        line-height: 36px;
    }
    .add_btn i{
        font-size: 11px !important;
    }
    .result_wpr.small table{
        min-width: auto !important;
    }
    .result_wpr table thead tr th:first-child {
        text-align: left;
        width: auto;
    }

    .result_wpr table tbody tr td:first-child {
        word-break: break-all;
        min-width: 250px;
        text-align: left;
    }

    .profile_spinner_wpr {
        z-index: 15;
    }

    .prefix-right {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #e6e6e6;
        font-size: 14px;
        line-height: 17px;
        font-weight: 300;
        color: #949494;
        padding: 0 15px;
        word-break: break-all;
        max-width: 250px;
    }

    .dropdown .drp_wrapper {
        display: none;
    }

    .dropdown.active .drp_wrapper {
        display: block;
    }

    .result_wpr.new.small .actions > ul li.search_area.active input[type=text] {
        height: 20px;
    }

    .result_wpr table.dns-table td {
        word-break: break-all;
    }

    .result_wpr table.dns-table td:first-child {
        min-width: 50px;
        word-break: auto-phrase;
    }

    .result_wpr table.dns-table td:last-child {
        min-width: 70px;
        word-break: auto-phrase;
    }

    @media(max-width: 767px){
        .result_wpr.small .actions{
            min-width: 100% !important;
        }
        .result_wpr.new.small .actions > ul li.search_area{
            width: 190px;
        }
        .result_wpr.new.small .actions > ul li.search_area input[type=text]{
            width: 100%;
            padding: 0 15px 0 0;
        }
    }
</style>
